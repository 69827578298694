import React, { useEffect, useRef, useState } from 'react';
import vial from './assets/vial_green.png';
import vial2 from './assets/vial.png';
import cover from './assets/cover.png';
import cover2 from './assets/cover_vol_2.png';
import crown from './assets/crown.png';
import footercs from './assets/footercs.svg'
import { FaAmazon } from 'react-icons/fa';

const Home: React.FC = () => {
  const [imageSize, setImageSize] = useState(100); // Initial size in percentage
  const newSizeRef = useRef(100);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const targetPosition = 600; // 10% of the window height

      if (scrollY < targetPosition) {
        newSizeRef.current = Math.max(35, 100 - (scrollY / targetPosition) * 65); // Shrink from 100% to 10%
        setImageSize(newSizeRef.current);
      } else {
        setImageSize(35);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (

    <div className="flex relative items-center justify-center min-h-screen">
      <div className="py-5 pt-64 w-[1080px] h-auto">
        <h1 className=" text-white text-center tracking-tighter text-5xl mx-22  md:text-7xl font-header mb-8">
          The Imminent Return
          <h1 className="pb-4">
            of History
          </h1>
          <h1 className=" text-white text-center pb-20 text-5xl md:text-7xl font-header mb-8">
            Book  II          </h1>
        </h1>
        <div className="flex flex-col -mb-96 inset-0 items-center" style={{ scale: `${imageSize}%` }}>
          <img
            id="vial"
            className={`transition ease-in-out delay-300`}
            src={vial}
            alt="Orange vial"
          />

        </div>
        <div className='pt-56 mt-10 md:mt-12 lg:w-[1080px] w-full inset-0 md:h-[1080px] p-6 md:overflow-hidden'
          style={{
            backgroundImage: `url(${crown})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className='tracking-wide  font-text pb-32 md:px-64 my-4 md:my-20 text-justify md:text-xl text-xl'>
            <p className='mb-8 drop-shadow-2xl'>
              In a world shaped by the rippling effects of war,
              free will became the chain that tied a king's vanity to his greed.            </p>

            <p className='mb-8 drop-shadow-2xl'>
              Having survived her trials, Tiadola discovered that appearances were deceiving.
              As secrets unravel, she must navigate the darkness clouding her mind while fighting
              for a chance at happiness with Zadrohn.             </p>
            <p className='mb-8 drop-shadow-2xl'>History repeats itself as magic and power intertwine, shifting the tides in
              King Tuddn's favour.
              The looming war between humans and Soronians is inevitable, and every conflict demands a victor.             </p>
            <p className='mb-8 drop-shadow-2xl'>
              Outnumbered and overpowered, yet resolute, can Tiadola and Zadrohn secure the human nation's independence,
              or will their efforts crumble under the weight of forces beyond their control?            </p>
          </div>
        </div>
        <div className=''>
          <div className='flex flex-col justify-center text-center space-y-0'>
            <a href="https://www.amazon.com/dp/B0DVW4SP6M" className='items-center ' target="_blank" rel="noopener noreferrer" aria-label="Amazon">
              <div className='flex justify-center space-x-5 pt-20 text-2xl font-text hover:text-gray-400'>ORDER NOW BOOK  II  HERE
                <FaAmazon className="w-12 h-8 hover:text-gray-400" />
              </div>
            </a>
          </div>
          <div className='md:-mt-80 md:-mb-96 px-0 pt-0'>
            <img
              id="cover_image_2"
              className="scale-75 md:scale-50 "
              src={cover2}
              alt="Cover Imminent Return of History"
            />
          </div>

        </div>
        <div>
          <div>
            <div className='flex flex-col scale-50 justify-center'>
              <img className="pt-5" src={footercs} alt="series" />
            </div>            <div>

              <h1 className=" text-white text-center tracking-tighter text-5xl mt-12  md:text-7xl font-header">
                The Imminent Trials
                <h1 className="pb-4">
                  of Quietus
                </h1>
                <h1 className=" text-white text-center pb-20 text-5xl md:text-7xl font-header mb-8">
                  Book  I
                </h1>
              </h1>
            </div>


          </div>
          <div className='flex flex-col justify-center text-center space-y-0'>

            <a href=" https://www.amazon.com/dp/B0D6W59KG3" className='items-center ' target="_blank" rel="noopener noreferrer" aria-label="Amazon">
              <div className='flex justify-center space-x-5 mb-10 mb-48 text-2xl font-text hover:text-gray-400'>ORDER NOW BOOK  I  HERE
                <FaAmazon className="w-12 h-8 hover:text-gray-400" />
              </div>
            </a>

            <div className="flex flex-col  items-center">
              <img
                id="vial"
                src={vial2}
                className="w-[10%] h-[10%]"
                alt="Orange vial"
              />
              <div className='pt-56 mt-10 md:mt-12 lg:w-[1080px] w-full inset-0 md:h-[1080px] p-6 md:overflow-hidden'
                style={{
                  backgroundImage: `url(${crown})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                <div className='tracking-wide  font-text pb-32 md:px-64 my-4 md:my-20 text-justify md:text-xl text-xl'>
                  <p className='mb-8 drop-shadow-2xl'>
                    In a world that long lost its moral axis, free will was the chain
                    that tied a god’s vengeance to his entertainment.
                  </p>

                  <p className='mb-8 drop-shadow-2xl'>
                    With nothing but hope to keep her dark thoughts afloat,
                    Tiadola awaited centuries for the preordained trials that would end her life to begin.
                    But nothing could have prepared her for the moment they were set in motion by encountering
                    the three-headed dragon compelled to hunt her.
                  </p>
                  <p className='mb-8 drop-shadow-2xl'> When the stranger Tiadola thought was a henchman in
                    charge of her capture became her friend instead, the lines between want and
                    need became a blur as they embarked together on a journey towards her imminent trials.
                  </p>
                  <p className='mb-8 drop-shadow-2xl'>
                    Caught between her desire to continue living in order to save the human nation she’d spent her
                    life protecting and the mental warfare that her trials would entail, would
                    Tiadola become the first of her kind to win?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='md:-mt-80  pt-0'>
            <img
              id="cover_image"
              className="scale-75 md:scale-50 "
              src={cover}
              alt="Cover Imminent return of Quietus"
            />
          </div>
        </div>
      </div>
    </div >
  );
};

export default Home;
